<template>
  <div class="flex xs12">
    <local-table
      class="table-hover table-striped"
      :editable="edit"
      :columns="presentationsFields"
      :data="presentationsList"
      :loading="loading || isLoading"
      @new-item="tryAdd"
      @delete-item="tryDelete"
      @download-item="prepareDownload"
    >
      <template v-slot:row-actions="{ props }">
        <va-popover
          :message="$t('tables.actions.images')"
          placement="left"
        >
          <va-button
            flat
            small
            color="primary"
            icon="fa fa-picture-o"
            @click.prevent="showImages(props.rowData)"
          />
        </va-popover>
        <va-popover
          v-if="edit && currentUser.can('Presentations', 'edit')"
          :message="$t('tables.actions.edit')"
          placement="left"
        >
          <va-button
            flat
            small
            color="warning"
            icon="fa fa-edit"
            @click.prevent="editPresentation(props.rowData)"
          />
        </va-popover>
        <va-popover
          v-if="edit && currentUser.can('Presentations', 'delete')"
          :message="$t('tables.actions.delete')"
          placement="left"
        >
          <va-button
            flat
            small
            color="danger"
            icon="fa fa-trash-o"
            @click.prevent="tryDelete(props.rowData)"
          />
        </va-popover>
      </template>
    </local-table>
    <va-modal
      ref="presentationModal"
      :title="$t('reports.modal.title')"
      :hide-default-actions="true"
      v-model="formModal"
    >
      <presentation-form
        v-if="edit"
        :reset="formModal"
        :remotePresentation="currentPresentation"
        :report="report"
        :loading="isLoading"
        @submit="updatePresentation"
      />
    </va-modal>
    <va-modal
      v-model="imagesModal"
      :hide-default-actions="true"
      :title="$t('reports.modal.images')"
      size="large"
    >
      <presentation-images :report="currentReport"></presentation-images>
    </va-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const PresentationForm = () => import(/* webpackPrefetch: true */ './Form')
const PresentationImages = () => import(/* webpackPrefetch: true */ './Images')
const LocalTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/LocalTable')

export default {
  name: 'presentations-table',
  components: {
    PresentationForm,
    PresentationImages,
    LocalTable,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      currentPresentation: {},
      currentReport: {},
      presentations: [],
      presentationsList: [],
      isLoading: false,
      imagesModal: false,
      formModal: false,
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2),
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    presentationsFields () {
      return [
        {
          name: 'presentation_date',
          title: this.$t('tables.headings.date'),
          type: 'date',
          callback: this.dateLabel,
          width: '150px',
        },
        {
          name: 'location',
          title: this.$t('tables.headings.location'),
        },
        {
          name: 'audience',
          title: this.$t('tables.headings.audience'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'newcomers',
          title: this.$t('tables.headings.newcomers'),
          type: 'number',
          width: '150px',
        },
        {
          name: 'regulars',
          title: this.$t('tables.headings.regulars'),
          type: 'number',
          width: '150px',
        },
        {
          name: '__slot:actions',
          visible: this.presentationsList.length > 0,
          dataClass: 'text-right',
          width: '20%',
        },
      ]
    },
  },
  watch: {
    report (val) {
      if (val.presentations && val.presentations.length > 0) {
        const presentations = this.fixData(val.presentations)
        this.presentationsList = presentations.slice(0)
      }
    },
  },
  methods: {
    dateLabel (date) {
      return this.$date.format(date.split('T')[0], 'dd-MM-yyyy')
    },
    fixData (data) {
      return data
    },
    async asyncFindPresentation (query) {
      if (query.length < 2) {
        this.presentations = []
        this.isLoading = false
        return
      }

      this.isLoading = true
      let response = false
      try {
        response = await this.$http.get('/presentations?q=' + query)
      } catch (err) {
        // console.log(err)
        this.isLoading = false
        return
      }

      this.presentations = response.data.data || []
      this.isLoading = false
    },
    tryAdd () {
      this.currentPresentation = Object.assign({})
      this.formModal = true
    },
    async editPresentation (presentation) {
      this.tryAdd()
      this.isLoading = true
      let p = false
      try {
        p = await this.$http.get('presentations/' + presentation.id)
      } catch (e) {
        // console.log('Error getting presentation', e)
        this.isLoading = false
        return
      }

      this.isLoading = false
      this.currentPresentation = p.data.data
    },
    async showImages (presentation) {
      this.isLoading = true
      let p = false
      try {
        p = await this.$http.get('presentations/' + presentation.id)
      } catch (e) {
        // console.log('Error getting presentation', e)
        this.isLoading = false
        return
      }

      const data = { presentations: [p.data.data] }

      if (this.report.team) {
        if (this.report.team.code) {
          data.team = { code: this.report.team.code }
        }
      }

      this.isLoading = false
      this.currentReport = Object.assign({}, data)
      this.imagesModal = true
    },
    async addPresentation (presentation) {
      this.isLoading = true
      if (this.report.id) {
        presentation.append('proyection_id', this.report.id)
      }

      let p = false
      try {
        p = await this.$http.post('presentations', presentation, { headers: this.headers })
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false
        return
      }

      const presentationDate = this.$date.parse(presentation.get('presentation_date'))
      const presentationObj = {
        id: p.data.data.id,
        presentation_date: this.$date.format(presentationDate, 'yyyy-MM-dd'),
        location: presentation.get('location'),
        audience: presentation.get('audience'),
        newcomers: presentation.get('newcomers'),
        regulars: presentation.get('regulars'),
      }
      this.presentationsList.push(presentationObj)
      this.fixData(this.presentationsList)
      this.isLoading = false
    },
    async updatePresentation (presentation) {
      this.isLoading = true
      let route = 'presentations/'
      if (this.report.id) {
        presentation.append('proyection_id', this.report.id)
      }
      if (this.currentPresentation.id) {
        presentation.append('id', this.currentPresentation.id)
        presentation.append('_method', 'PUT')
        route = route + this.currentPresentation.id
      }

      let p = false
      try {
        p = await this.$http.post(route, presentation, {
          headers: this.headers,
        })
      } catch (e) {
        // console.log('Error', e)
        this.isLoading = false
        return
      }

      const presentationDate = this.$date.parse(presentation.get('presentation_date'))
      const presentationObj = {
        id: p.data.data.id,
        presentation_date: this.$date.format(presentationDate, 'yyyy-MM-dd'),
        location: presentation.get('location'),
        audience: presentation.get('audience'),
        newcomers: presentation.get('newcomers'),
        regulars: presentation.get('regulars'),
        // type_asistance: presentation.get('type_asistance'),
      }

      const oldIndex = this.presentationsList.findIndex(p => p.id === this.currentPresentation.id)
      if (oldIndex === -1) {
        this.presentationsList.push(presentationObj)
      } else {
        let old = this.presentationsList[oldIndex]
        presentationObj.id = old.id
        old = Object.assign({}, presentationObj)
        this.presentationsList[oldIndex] = { ...old }
      }

      this.presentationsList = this.fixData(this.presentationsList).slice(0)
      this.isLoading = false
      this.formModal = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })

      if (result.value) {
        this.isLoading = true
        try {
          await this.$http.delete('presentations/' + item.id)
        } catch (e) {
          // console.log('Error', e)
          this.isLoading = false
        }

        this.isLoading = false
        this.presentationsList = this.presentationsList.filter(m => m.id !== item.id)
      }
    },
    async prepareDownload (format) {
      const prepareRoute = 'presentations/download?proyection=' + this.report.id
      const downloadRoute = 'presentations/download/'

      return this.downloadFile({ format: format }, prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
    submit () {
      // this.$emit('submit', this.presentationsList)
      this.$emit('submit', this.presentationsList.map(m => m.id))
    },
  },
}
</script>
